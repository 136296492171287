import { template as template_2435ed1ca16a4f288836dbabd7ceb64c } from "@ember/template-compiler";
const FKText = template_2435ed1ca16a4f288836dbabd7ceb64c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
