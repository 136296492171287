import { template as template_e8343b3080a94d6f96e2b547ad8f370f } from "@ember/template-compiler";
const FKLabel = template_e8343b3080a94d6f96e2b547ad8f370f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
