import { template as template_6a172d18cc484c539a6b495e29e601d7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6a172d18cc484c539a6b495e29e601d7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
